body {
  margin: 0;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scroll */
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 15px;
  color: #444444
}

html {
  scroll-behavior: smooth;
  /* scroll */
  overflow-x: hidden;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.goDown {
  padding: 10px 20px;
  background-color: #111111;
  border: none;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  margin-top: 5px;
}

button.goUp {
  padding: 5px 10px;
  background-color: #ffffff;
  border: none;
  border: 0;
  border-radius: 5px;
  color: #AAAAAA;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 10px;
}

.question {
  font-size: 25px;
  color: #555555;
}

span.highlight {
  color: #000000;
}

div.selection {
  padding: 30px 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.titleStart {
  font-size: 40px;
  font-weight: 300;
}

div.littleTitle {
  font-size: 13px;
  color: #777777;
  text-align: center;
  font-weight: 300;
  margin-bottom: 7px;
}

div.description {

  padding: 0 30px 30px 30px;
  max-width: 700px;

  font-size: 15px;
  line-height: 22px;
  color: #777777;
  text-align: center;

}

div.disabled {
  opacity: .5;
  cursor: default !important;
  border: 1px solid #CCCCCC !important;
}

a {
  color: steelblue;
}

h1.intervalInterval {
  font-size: 60px;
  padding: 0;
  margin: 30px;
  font-weight: 300;
  color: #000;

  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

div.localIcon {
  text-align: center;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
}

textarea.finalComment {

  padding: 20px;
  max-width: 700px;
  min-width: 500px;
  min-height: 100px;

  border: 1px solid #CCCCCC !important;
  background-color: #eeeeee;

  font-size: 15px;
  line-height: 22px;
  color: #777777;

  border-radius: 10px;
}